import React from 'react'

import { graphql, StaticQuery } from 'gatsby'
import PortableText from '@sanity/block-content-to-react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

import Collapsible from 'react-collapsible'

import { IoIosArrowDown } from "react-icons/io";

import styles from './tools.module.css'
import serializers from '../lib/serializers'
import SEO from '../components/seo'


export const query = graphql`
    query ToolsQuery($id: String!) {
        tools: sanityContentPage(id: { eq: $id }) {
        id
        title
        subTitle
        directory
        _rawContentSections(resolveReferences: {maxDepth: 10})
        _rawAdditionalContent(resolveReferences: {maxDepth: 10})
        }
    }
`

class Tools extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            activeAccordion: 0
        }
    }

    openLinks = (idx) => {
        this.setState({
            activeAccordion: idx,
        })
    }

    closeLinks = (idx) => {
        if (this.state.activeAccordion === idx) {
            this.setState({
                activeAccordion: 0
            })
        }
    }

    getQuote = (quote) => {
        if (quote.mainImage.asset) {
            return (
                <div className={styles.quoteWrapper}>
                    <img src={quote.mainImage.asset.url}></img>
                    <div>
                        <p>{quote.quote}</p>
                        <p className={styles.author}>- {quote.authors[0].author.name}</p>
                    </div>
                </div>
            )
        }
        
        return null;
    }

    getImage = (sideImage) => {
        if(sideImage.FullImage.asset) {
            return(
                <div className={styles.sideImageContainer}>
                    <a href={sideImage.url ? sideImage.url.href : null}>
                        <img src={sideImage.FullImage.asset.url} className={styles.sideImage} alt={sideImage.alt || null}></img>
                    </a>
                    <span>{sideImage.description}</span>
                </div>
            )
        } else if (sideImage.smallImage.asset) {
            return(
                <div className={styles.sideImageContainer}>
                    <a href={sideImage.url ? sideImage.url.href : null}>
                        <img src={sideImage.smallImage.asset.url} className={styles.sideImage} alt={sideImage.alt || null}></img>
                    </a>
                    <span>{sideImage.description}</span>
                </div>
            )
        }
        
        return null;
    }

    getLinkLists = (contents) => {
        const additionalContent = [];
        contents.map((content, idx) => {
            switch (content.linkListShowAs) {
                case ('linkTiles'):
                    additionalContent.push(this.getLinkTilesList(content));
                    break;
            }
        })
        return additionalContent;
    }

    getLinkTilesList = (content) => {
        const contList = content._rawLinkList;
        
        const contComponent = contList.map((cont, idx) => {
            //Need to find out if external or internal, then output accordingly. 
            //External use <a>,
            //path = node.directory + '/' + title.replace(/\s+/g, '-').toLowerCase();
            //Internal use <Link to= {path}>

            const path = cont.internalLink ? cont.internalLink.reference.directory + '/' + cont.internalLink.reference.title.replace(/\s+/g, '-').toLowerCase() : null;
            const link = (path !== null ? <Link to={path}><button>{cont.buttonText}</button></Link> : <a href={cont.url}><button>{cont.buttonText}</button></a>)

            return (
                <div key={cont._key} className={styles.linkTile}>
                    <div className={styles.linkTileTitle}>
                        <h2>{cont.title || null}</h2>
                    </div>
                    <div className={styles.linkTileDescription}>
                        <PortableText
                            blocks={cont.description}
                            serializers={serializers}
                            className={styles.portableText}
                        />
                    </div>
                    <div className={styles.buttons}>
                        {link}
                    </div>
                </div>
            )
        })
       
        let listComponent = '';
        
        listComponent = (
            <div key={content.id} className={styles.linkTiles}>
                {contComponent}
            </div>
        )
        
        
        return listComponent;
    }

    render() {
        const data = this.props.data;

        if (!data.tools) {
            throw new Error(
                'Missing "Tools". Open the Studio and some content in "Tools"'
            )
        }
        
        const toolsData = data.tools;  

        /*if (toolsData._rawContentSections.length === 0) {
            return (
                <Layout>
                    <div className={styles.root}>
                        <h1>No tools data yet</h1>
                    </div>
                </Layout>
            )
        }*/

        let sideComponent = null;
        
        if (toolsData._rawAdditionalContent) {
            sideComponent = this.getLinkLists(toolsData._rawAdditionalContent);
        }

        const contentComponents = toolsData._rawContentSections.map((content, idx) => {   
            let quoteComponent = '';
            let imageComponent = '';

            const sideContent = content.sideContent || null;

            if(sideContent !== null) {
                if(sideContent.quote) {
                    quoteComponent = this.getQuote(sideContent.quote);
                }
                if(sideContent.sideImage) {
                    imageComponent = this.getImage(sideContent.sideImage);
                }
            }

            if (content.showAsAccordion !== true) {
                
                if(!content.paragraph) {
                    return (
                        <div key={content._key} className={styles.contentDiv}>
                            <h1>{content.contentSectionTitle}</h1>
                            <h2>{content.contentSectionSubTitle}</h2>
                            {imageComponent}
                            {quoteComponent}
                        </div>
                    )
                }

                return (
                    <div key={content._key} className={styles.contentDiv}>
                        <h1>{content.contentSectionTitle}</h1>
                        <h2>{content.contentSectionSubTitle}</h2>
                        <div className={styles.contentSection}>
                            {imageComponent}
                            <PortableText
                                blocks={content.paragraph}
                                serializers={serializers}
                                className={styles.portableText}
                            />
                            {quoteComponent}
                        </div>
                    </div>
                )
            } else {
                const triggerTitle =
                    <div className={styles.triggerTitle}>
                        {content.contentSectionTitle}
                        <span><IoIosArrowDown /></span>
                    </div>

                return (
                    <Collapsible trigger={triggerTitle} key={content._key}
                        className={styles.sectionBox}
                        triggerClassName={styles.sectionTrigger}
                        triggerOpenedClassName={styles.sectionTriggerOpened}
                        openedClassName={styles.sectionOpened}
                        transitionTime={100}
                        transitionCloseTime={100}
                        onOpening={() => this.openLinks(idx)}
                        onClosing={() => this.closeLinks(idx)}
                        open={this.state.activeAccordion === idx ? true : false}
                        lazyRender={true}
                    >
                        <div className={styles.sectionContent} style={{display: this.state.activeAccordion === idx ? 'block' : 'none'}}>
                            <h2>{content.contentSectionSubTitle}</h2>
                            {imageComponent}
                            <PortableText 
                                blocks={content.paragraph}
                                serializers={serializers}
                                className={styles.portableText}
                            />
                            {quoteComponent}
                        </div>
                    </Collapsible>
                )
            }            
        })

        const path = toolsData.directory + '/' + toolsData.title.replace(/\s+/g, '-').toLowerCase();

        return (
            <Layout>
                <SEO
                    title={toolsData.title}
                    description={toolsData.subTitle}
                />
                <div className={styles.root}>
                    <p className={styles.upperLeftLink}><Link to={`/`} style={{ textDecoration: 'none' }}>Home</Link> {path}</p>
                    <h1>{toolsData.title}</h1>
                    <p>{toolsData.subTitle}</p>
                    <div className={styles.containers}>
                        {contentComponents}
                        {sideComponent}
                    </div>
                    
                </div>
            </Layout>
        )
    }
}

export default Tools;